import React, { useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

import { toast } from 'react-toastify';
import Modal from './Modal';
import { useAccountService } from '../../../services/account.service';

const Settings = () => {
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [match] = useState(true);
  const [password, setPassword] = useState('');
  const [password1, setPassword1] = useState('');
  const [isPending, setIsPending] = useState(false);
  const { resetPassword } = useAccountService();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== password1) {
      toast.error('New password does not match');
      return;
    }

    setIsPending(true);
    try {
      await resetPassword(password);
      toast.success('Password changed successfully');
    } catch (error) {
      console.error('Error:', error);
      toast.error(error + '');
    } finally {
      setIsPending(false);
      setPassword('');
      setPassword1('');
    }
  };

  const clearInput = () => {
    setPassword('');
    setPassword1('');
  };

  const valid = password && password1;

  return (
    <>
      <Modal showModal={showModal} setShowModal={setShowModal} />
      <div className="flex justify-center">
        <div className="content w-full">
          <h1 className="mb-4">Settings</h1>
          <main className="bg-white rounded-lg py-8 px-3 md:p-8 text-[#585858]">
            <div className="flex justify-start items-start">
              <h1 className="mb-4 cursor-pointer text-[grey] border-b border-black pb-1 w-fit">
                Change Password
              </h1>
            </div>

            <form action="" onSubmit={handleSubmit}>
              <div className="rows grid grid-cols-3">
                <div className="col col-span-3 md:col-span-2">
                  <label htmlFor="" className="text-[#808080]">
                    New Password
                  </label>
                  <div className="current relative">
                    <input
                      type={show1 ? 'text' : 'password'}
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Type your new password"
                      className={`px-3 py-2 border ${
                        match ? 'border-[#808080]' : 'border-[red] text-[red]'
                      } w-full mt-3 mb-4`}
                    />
                    <div
                      className="eye absolute text-3xl top-6 right-5 cursor-pointer"
                      onClick={() => setShow1(!show1)}
                    >
                      {show1 ? <AiFillEyeInvisible /> : <AiFillEye />}
                    </div>
                  </div>
                  <label htmlFor="" className="text-[#808080]">
                    Confirm Password
                  </label>
                  <div className="current relative">
                    <input
                      type={show2 ? 'text' : 'password'}
                      required
                      value={password1}
                      onChange={(e) => setPassword1(e.target.value)}
                      placeholder="Type your password again"
                      className="px-3 py-2 border border-[#808080] w-full mt-3 mb-4"
                    />
                    <div
                      className="eye absolute text-3xl top-6 right-5 cursor-pointer"
                      onClick={() => setShow2(!show2)}
                    >
                      {show2 ? <AiFillEyeInvisible /> : <AiFillEye />}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 flex justify-start items-center">
                <input
                  type="button"
                  value="cancel"
                  onClick={clearInput}
                  className="px-8 border border-[#808080] mr-6"
                />

                <button
                  disabled={!valid}
                  className={`px-8 ${
                    !valid ? 'bg-[#D6E6FF]' : 'bg-blue-ribbon-500'
                  } text-white`}
                >
                  {isPending ? 'Please wait...' : 'Submit'}
                </button>
              </div>
            </form>
          </main>
        </div>
      </div>
    </>
  );
};

export default Settings;
