import { useGlobalContext } from '../context/context';
import { createData, fetchData, updateData } from '../hooks/api';

export const useAccountService = () => {
  const { workspace } = useGlobalContext();
  const fetchAccount = async () => {
    return fetchData('/me');
  };

  const joinCohort = async (form) => {
    return createData('/users/join', form, workspace, {});
  };

  const resetPassword = async (form) => {
    return updateData('/me', '', form, null, {});
  };

  return {
    fetchAccount,
    joinCohort,
    resetPassword,
  };
};
